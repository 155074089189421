<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 mb-4">
      <div class="col-span-1 flex items-center flex-wrap">
        <h2 class="text-2xl font-bold mr-3">Transactions</h2>
      </div>
      <div class="col-span-1">
        <search-form
          placeholder="Search order no, branch...."
          class="mb-5"
          v-model="transactionResource.query"
          @submit="$refs.table.loadAjaxData()"
        />
      </div>
    </div>

    <div class="border border-solid border-blue-200">
      <div class="flex flex-col">
        <div class="duration-300">
          <datatable
            :url="transactionResource.url"
            :ajax="true"
            :ajax-pagination="true"
            :columns="transactionResource.columns"
            :query="transactionResource.query"
            :fillable="false"
            :on-click="showDetails"
            ref="table"
          />
        </div>
      </div>
    </div>

    <modal
      className="w-11/12 sm:w-443px flex flex-col"
      ref="transactionDetailsModal"
    >
      <div class="sm:-mx-6">
        <h4 class="text-13 text-markeplace-blue -mt-6">Payment reciept</h4>
        <div class="flex justify-center mt-6">
          <logo-round></logo-round>
        </div>
        <div class="mt-4 flex flex-col text-marketplace-blue text-center">
          <span class="text-12 px-6 sm:px-12 leading-relaxed font-normal">
            {{ statusProgress() }}
          </span>
          <h2 class="font-extrabold text-32 mt-4">
            {{ orderAmount }}
          </h2>
        </div>

        <div class="border-1 bg-marketplace-blue bg-opacity-85 mt-8"></div>
        <h4 class="text-13 text-markeplace-blue mt-5">Transaction details</h4>
        <div class="order mt-2">
          <h1 class="order-text">Order amount</h1>
          <h1 class="order-text">
            {{ orderAmount }}
          </h1>
        </div>

        <div class="order">
          <h1 class="order-text">Transaction date</h1>
          <h1 class="order-text">{{ transactionDate }}</h1>
        </div>

        <div class="order">
          <h1 class="order-text">Reference number</h1>
          <h1 class="order-text">
            {{ reference }}
          </h1>
        </div>

        <div class="order">
          <h1 class="order-text">Merchant</h1>
          <h1 class="order-text">
            {{ merchantName }}
          </h1>
        </div>
        <div v-if="branch">
          <h4 class="text-13 text-markeplace-blue mt-6">Branch Details</h4>
          <div class="order">
            <h1 class="order-text">Name</h1>
            <h1 class="order-text">
              {{ branchName | sentenceCase }}
            </h1>
          </div>
          <div class="order">
            <h1 class="order-text">Address</h1>
            <h1 class="order-text">
              {{ branchAddress }}
            </h1>
          </div>
          <div class="order">
            <h1 class="order-text">Phone Number</h1>
            <h1 class="order-text">
              {{ branchPhone }}
            </h1>
          </div>
          <div class="order" v-if="!loan">
            <h1 class="order-text">Account Details</h1>
            <h1 class="order-text">
              {{ branchAccountDetails }}
            </h1>
          </div>
        </div>
        <div class="order">
          <h1 class="order-text">Payment Channel</h1>
          <h1 class="order-text text-green-600">
            {{ paymentChannel | fromSlug }}
          </h1>
        </div>
        <div v-if="equityContribution">
          <h4 class="text-13 text-markeplace-blue mt-6">Equity Contribution</h4>
          <div class="order">
            <h1 class="order-text">Amount Paid</h1>
            <h1 class="order-text">
              {{ equityAmount }}
            </h1>
          </div>
          <div class="order">
            <h1 class="order-text">Equity Percentage</h1>
            <h1 class="order-text">
              {{ equityPercentage }}
            </h1>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      transactionResource: this.$options.resource([], {
        url: `${this.$baseurl}/admin/merchants/all/orders`,
        query: '',
        columns: [
          {
            name: 'order_no',
            th: 'Order No'
          },
          {
            name: 'merchant',
            th: 'Merchant',
            render: ({ merchant }) => `${merchant?.business_name}`
          },
          {
            name: 'user',
            th: 'Customer',
            render: ({ user }) => `${user?.name} ${user?.last_name}`
          },
          {
            name: 'phone_no',
            th: 'Phone Num',
            render: ({ user }) => `${user?.phone_no}` || '----'
          },
          {
            name: 'item',
            th: 'Item'
          },
          {
            name: 'amount',
            th: 'Amount',
            render: transaction =>
              this.$options.filters.formatAmount(transaction?.price)
          },
          {
            name: 'branch',
            th: 'Branch',
            render: ({ branch }) => branch?.name ?? '----'
          },
          {
            name: 'status',
            th: 'Payment Status',
            render: (transaction, status) => this.getStatusBadge(status)
          },
          {
            name: 'channel',
            th: 'Payment Channel',
            render: ({ channel }) =>
              this.$options.filters?.fromSlug(channel) ?? 'In App'
          },
          {
            name: 'createdAt',
            th: 'Date',
            render: transaction =>
              this.$moment(transaction?.created_at).format(
                'dddd, MMMM Do YYYY, h:mm:ss a'
              )
          }
        ]
      }),
      transactionDetails: {}
    };
  },
  computed: {
    ...mapState(['banks']),
    branch() {
      return this.transactionDetails?.branch;
    },
    branchName() {
      return this.branch?.name;
    },
    branchAddress() {
      return this.branch?.address;
    },
    branchPhone() {
      return this.branch?.phone;
    },
    branchAccountDetails() {
      const bankName = this.banks?.find(
        bank => bank.code === this.branch?.bank_name
      )?.name;
      return `${this.branch?.account_no} * ${bankName}`;
    },
    merchant() {
      return this.transactionDetails?.merchant;
    },
    merchantName() {
      return this.merchant?.business_name;
    },
    reference() {
      return this.transactionDetails?.order_no;
    },
    paymentChannel() {
      return this.transactionDetails?.channel ?? 'In App';
    },
    transactionDate() {
      return this.$moment(this.transactionDetails?.created_at).format(
        'dddd, MMMM Do YYYY'
      );
    },
    loan() {
      return this.transactionDetails?.loan;
    },
    equityContribution() {
      return this.loan?.equity_contribution;
    },
    equityAmount() {
      return this.$options.filters.formatAmount(
        this.equityContribution?.amount_paid
      );
    },
    equityPercentage() {
      return `${this.equityContribution?.equity_percentage}%`;
    },
    orderAmount() {
      return this.$options.filters.formatAmount(this.transactionDetails?.price);
    },
    paymentStatus() {
      return this.transactionDetails.status;
    },
    orderStatus() {
      return this.transactionDetails?.order_status;
    }
  },
  methods: {
    showDetails(order) {
      this.transactionDetails = { ...order };
      this.$refs.transactionDetailsModal.open();
    },
    statusProgress() {
      switch (this.paymentStatus) {
        case 'pending':
          return `Payment is currently processing`;
        case 'success':
          return `Payment was successful and has been received by ${this.merchantName}.`;
        case 'confirmed':
          return 'Payment has been confirmed and awaiting to be disbursed';
        case 'disbursed':
          return `Payment has been disbursed to ${this.merchantName}.`;
        case 'failed':
        case 'cancelled':
          return `Payment failed`;
        default:
      }
    },
    getStatusBadge(status) {
      switch (status) {
        case 'pending':
          return `<div class="badge badge-orange-soft-outline px-7">
                  Pending
                </div>`;
        case 'processing':
          return `<div class="badge badge-blue-soft-outline px-7">
                  Processing
                </div>`;
        case 'cancelled':
          return `<div class="badge badge-red-soft-outline px-7">
                  Cancelled
                </div>`;
        case 'confirmed':
        case 'disbursed':
        case 'success':
        case 'delivered':
          return `<div class="badge badge-green-soft-outline px-7">
                  ${status}
                </div>`;
        default:
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.order {
  @apply flex;
  @apply justify-between;
  @apply items-center;
  @apply text-marketplace-blue;
  @apply mt-8;
  &-text {
    @apply text-11;
    @apply font-normal;
  }
}
</style>
